// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Styles from "@material-ui/core/styles";

import "@fontsource/noto-sans"
;

import "@fontsource/noto-serif"
;

import "@fontsource/noto-mono"
;

function getThemeProto(mode) {
  return {
          palette: {
            primary: {
              dark: "#273377",
              light: "#606dbb",
              main: "#3949ab"
            },
            secondary: {
              dark: "#b22a00",
              light: "#ff6333",
              main: "#ff3d00"
            },
            text: mode ? ({
                  primary: "#dadada"
                }) : ({}),
            type: mode ? "dark" : "light"
          },
          typography: {
            fontFamily: "Noto Sans, Arial",
            fontSize: 14.0
          }
        };
}

function getMode(theme) {
  if (theme.palette.type === "dark") {
    return /* Dark */1;
  } else {
    return /* Light */0;
  }
}

function initializeDarkMode(param) {
  var str = window.localStorage.getItem("darkMode");
  if (str == null) {
    return false;
  } else {
    return str === "true";
  }
}

var useStyles = Styles.makeStyles(function (_theme) {
      return {
              sidepane: {
                height: "100vh",
                overflow: "hidden",
                position: "sticky",
                top: "0px",
                zIndex: "40",
                gridColumn: "2",
                gridRowEnd: "3",
                gridRowStart: "2"
              },
              sidebar: {
                height: "100%",
                left: "0px",
                position: "fixed",
                top: "0px",
                zIndex: "60"
              },
              topbar: {
                zIndex: "50",
                gridColumnEnd: "4",
                gridColumnStart: "1",
                gridRow: "1"
              },
              wrapper: {
                display: "grid",
                gridTemplateRows: "56px 32px 1fr",
                transition: "0.2s",
                transitionProperty: "gridTemplateColumns"
              }
            };
    });

var Styles$1 = {
  useStyles: useStyles
};

export {
  getThemeProto ,
  getMode ,
  initializeDarkMode ,
  Styles$1 as Styles,
  
}
/*  Not a pure module */
